import React, { useState, useEffect } from "react";
import { Button, Typography, Row, Col, message, Spin, Modal } from "antd";
import "./../styles/SwitchButton.css";
import { useNavigate, useLocation } from "react-router-dom";
import AnimatedNumber from "../components/AnimatedNumber";
import { auth, database } from "../firebase";
import { get, ref } from "firebase/database";
import AfterLoginLayout from "./AfterLoginLayout";

const { Title } = Typography;

export default function SwitchButton() {
  const navigate = useNavigate();
  const location = useLocation();

  const [TodaysDate, setTodaysDate] = useState();
  const [user, setUser] = useState(null);
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState("report");

  const handleModeChange = (value) => {
    if (value === "feedback") {
      setShowFeedbackModel(true);
    } else {
      navigate(`/${value}`);
    }
  };

  useEffect(() => {
    setTodaysDate(new Date().toISOString().split("T")[0].replace(/-/g, ""));
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoading(true);
        const fetchUserData = async () => {
          try {
            const userRef = ref(database, `Users/${user.uid}`);
            const snapshot = await get(userRef);
            const userData = snapshot.val();
            if (userData) {
              setUser(userData);
            }
          } catch (error) {
            message.error(`Can Not fetch User Data`);
          } finally {
            setIsLoading(false);
          }
        };

        fetchUserData();
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setMode(location.pathname.replace("/", ""));
  }, [location.pathname]);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", paddingTop: "20%" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col
          span={24}
          className="content-center"
          style={{ margin: "3em 0em 1em 0em" }}
        >
          <div className="button-container">
            <Button
              className={`custom-button report-button ${
                mode === "report" ? "active-button" : ""
              }`}
              onClick={() => handleModeChange("report")}
            >
              Report
            </Button>
            <Button
              className={`custom-button feedback-button  ${
                mode === "feedback" ? "active-button" : ""
              }`}
              onClick={() => handleModeChange("feedback")}
            >
              Feedback
            </Button>
          </div>
        </Col>
        <Col span={24} className="content-center">
          <AnimatedNumber target={user?.Overall?.total_question_Attempt} />
        </Col>
        <Col span={24} className="content-center">
          <Title className="middle-font">
            Total Question Attempted Till Date
          </Title>
        </Col>
      </Row>

      <Modal
        title="Feedback"
        open={showFeedbackModel}
        onOk={() => setShowFeedbackModel(false)}
        onCancel={() => setShowFeedbackModel(false)}
        okText="Ok"
      >
        <p>
          Additional Data Needed: Continue your practice! Feedback will be
          provided once we've accumulated sufficient data. (Please allow 7 days
          for data collection before expecting feedback.)
        </p>
      </Modal>
    </>
  );
}
