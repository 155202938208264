import React, { useState, useEffect } from "react";
import { Col, Typography } from "antd";
const { Title } = Typography;

const AnimatedNumber = ({ target }) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    let interval;
    if (number < target) {
      interval = setInterval(() => {
        setNumber((prevNumber) => {
          const increment = Math.ceil(target / 100);
          return prevNumber + increment > target
            ? target
            : prevNumber + increment;
        });
      }, 30);
    }
    if (number === target && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [number, target]);

  return (
    <Col span={24} className="content-center">
      <Title className="middle-font-number">{number}</Title>
    </Col>
  );
};

export default AnimatedNumber;
