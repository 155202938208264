import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Alert,
  Space,
  Card,
  Row,
  Col,
  message,
  Image,
  Spin,
  Modal,
  Typography,
  Button,
} from "antd";
import AfterLoginLayout from "../components/AfterLoginLayout";
import stop from "../assets/stop.png";
import { database, auth, analytics } from "../firebase";
import { useNavigate } from "react-router-dom";
import { get, ref } from "firebase/database";
import { logEvent } from "firebase/analytics";

function PracticeQuestion() {
  const { Text } = Typography;

  const [timer, setTimer] = useState(0);
  const [question, setQuestion] = useState(null);
  const [todaysQuestions, setTodaysQuestions] = useState(0);
  const [showSolution, setShowSolution] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [OverallQuestions, setOverallQuestions] = useState(0);
  const [DailyQuestions, setDailyQuestions] = useState(0);
  const [userId, setUserId] = useState();
  const [solNextText, setSolNextText] = useState("Show Image");
  const [todaysdate, setTodaysdate] = useState();
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const MainTopic = "Creative Ability Test";
  const [questionLimit, setQuestionLimit] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [OpenTut, setOpenTut] = useState(null);

  const [isModalRedirectVisible, setIsModalRedirectVisible] = useState(false);

  const timerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "Practice CAT" });
  }, []);

  const fetchTodaysQuestionForUser = useCallback(
    async (userId) => {
      const snapshot = await get(
        ref(database, `Users/${userId}/todays_question_CAT`)
      );
      const snapshotOverall = await get(
        ref(database, `Users/${userId}/Overall/total_question_Attempt_CAT`)
      );
      const snapshotDaily = await get(
        ref(
          database,
          `Users/${userId}/Daily/${todaysdate}/total_question_Attempt_CAT`
        )
      );
      const premiumStatusSnapshot = await get(
        ref(database, `Users/${userId}/premium`)
      );
      const userRef = ref(database, `Users/${userId}`);
      const usersnapshot = await get(userRef);
      const userData = usersnapshot.val();

      const questionLimit = premiumStatusSnapshot.val() ? 5 : 1;
      setQuestionLimit(questionLimit);
      if (snapshot.val() >= questionLimit) {
        message.info("You have used your daily limit. Come back tomorrow.");
        navigate("/selection");
      }
      setUser(userData);
      setIsPremiumUser(premiumStatusSnapshot.val());
      setTodaysQuestions(snapshot.val());
      setOverallQuestions(snapshotOverall.val());
      setDailyQuestions(snapshotDaily.val());
    },
    [todaysdate, navigate]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setTodaysdate(new Date().toISOString().split("T")[0].replace(/-/g, ""));
        fetchTodaysQuestionForUser(user.uid);
      } else {
        return (
          <div style={{ textAlign: "center", paddingTop: "20%" }}>
            <Spin size="large" />
          </div>
        );
      }
    });

    return () => unsubscribe();
  }, [fetchTodaysQuestionForUser]);

  const fetchQuestionForUser = useCallback(async () => {
    const lastAttemptedQuestionSnapshot = await get(
      ref(database, `Users/${userId}/Overall/total_question_Attempt_CAT`)
    );
    const lastAttemptedQuestion = lastAttemptedQuestionSnapshot.val();

    if (!lastAttemptedQuestion) {
      const question = await fetchQuestionBySequenceId(1);
      setQuestion(question);
    } else {
      const question = await fetchQuestionBySequenceId(
        lastAttemptedQuestion + 1
      );
      setQuestion(question);
    }
  }, [userId]);

  const fetchNextQuestion = useCallback(async () => {
    fetchQuestionForUser(MainTopic, MainTopic);
  }, [fetchQuestionForUser]);

  useEffect(() => {
    fetchNextQuestion();
  }, [fetchNextQuestion, todaysQuestions]);

  const fetchQuestionBySequenceId = async (sequenceId) => {
    const questionSnapshot = await database
      .ref(`Questions/${MainTopic}/${MainTopic}`)
      .orderByChild("sequence_id")
      .equalTo(sequenceId)
      .once("value");
    const questionData = questionSnapshot.val();
    const key = Object.keys(questionData)[0];
    return questionData[key];
  };

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [timer]);

  useEffect(() => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
  }, [question, showSolution]);

  const handleShowHint = () => {
    setShowSolution(true);
    setSolNextText("Show Image");
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
  };

  const handleNext = () => {
    if (showImage) {
      const questionLimit = isPremiumUser ? 5 : 1;
      const updatedQuestionsCount = todaysQuestions + 1;
      const updatedOverallQuestionCount = OverallQuestions + 1;
      const updateDailyQuestionCount = DailyQuestions + 1;
      database
        .ref(`Users/${userId}/todays_question_CAT`)
        .set(updatedQuestionsCount);
      database
        .ref(`Users/${userId}/Overall/total_question_Attempt_CAT`)
        .set(updatedOverallQuestionCount);
      database
        .ref(`Users/${userId}/Daily/${todaysdate}/total_question_Attempt_CAT`)
        .set(updateDailyQuestionCount);
      stopTimer();
      if (todaysQuestions + 1 >= questionLimit) {
        setIsModalRedirectVisible(true);
        return;
      }

      setTimer(0);
      setShowSolution(false);
      setShowImage(false);
      setOverallQuestions(updatedOverallQuestionCount);
      setTodaysQuestions(updatedQuestionsCount);
      setDailyQuestions(updateDailyQuestionCount);
    } else {
      setShowImage(true);
      setSolNextText("Next");
    }
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  }

  const showConfirmModal = () => {
    setIsModalVisible(true);
  };

  const handleTut = () => {
    setOpenTut(true);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePaymentSuccess = async (response) => {
    const paymentRecord = {
      paymentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      signature: response.razorpay_signature,
      createdAt: new Date().toISOString(),
    };

    try {
      const userId = user.uid;
      await database.ref(`Payments/${userId}`).push(paymentRecord);
      await database.ref(`Users/${userId}`).update({ premium: true });
      navigate("/selection");
    } catch (error) {
      console.error("Error saving payment record:", error);
    }
  };

  const loadRazorpay = (details) => {
    const options = {
      key: "rzp_live_bMK7LvizPK6oVa",
      amount: details.amount,
      currency: details.currency,
      name: "AI NIFT EXAM PREP",
      description: "Payment for Nift Exam Prep",
      order_id: details.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: user.displayName,
        email: user.email,
        contact: "",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const processPayment = async (paymentData) => {
    try {
      const response = await fetch(
        "https://asia-southeast1-nift-exam.cloudfunctions.net/processPayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const orderDetails = await response.json();
      loadRazorpay(orderDetails);
    } catch (error) {
      console.error("Error during the payment process:", error);
    }
  };

  const handlePayment = () => {
    const paymentDetails = {
      amount: 49900,
    };
    processPayment(paymentDetails);
  };

  if (!question && !userId) {
    return (
      <AfterLoginLayout>
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <Spin size="large" />
        </div>
      </AfterLoginLayout>
    );
  }

  return (
    <>
      <AfterLoginLayout>
        <Button
          type="primary"
          className="btn-login back"
          onClick={showConfirmModal}
          style={{
            marginLeft: "45px",
            marginTop: "15px",
          }}
        >
          Back
        </Button>
        <div className="app">
          <Row className="timer-section" gutter={[20, 0]}>
            <Col xs={12} md={20} lg={18}>
              <Card className="Main-timer-card">
                <Row>
                  <Space>
                    <Card className="Inner-timer-card">
                      <span>
                        {!todaysQuestions ? 1 : todaysQuestions}/{questionLimit}
                      </span>
                    </Card>
                    <span>Time: {formatTime(timer)}</span>
                  </Space>
                </Row>
              </Card>
            </Col>
            <Col
              xs={6}
              md={4}
              lg={6}
              className="stop"
              style={{ marginTop: "0.2em" }}
            >
              {/* <img
                src={stop}
                width={30}
                height={30}
                alt="Stop"
                style={{ cursor: "pointer" }}
                onClick={showConfirmModal}
              /> */}
            </Col>
          </Row>
          <Card className="question-section" bordered={false}>
            <Button
              className="btn-login"
              size="large"
              type="primary"
              onClick={handleTut}
            >
              Watch Tutorial
            </Button>
            <br />
            <br />
            <span className="MainTopicText">Topic: {MainTopic}</span>
            <br />
            <span
              className="labelText"
              style={{
                display:
                  MainTopic === "English Comprehension" ? "block" : "none",
              }}
            >
              Passage:
            </span>
            <span dangerouslySetInnerHTML={{ __html: question?.passage }} />
            <br />
            <span className="labelText">Question:</span>
            <span dangerouslySetInnerHTML={{ __html: question?.question }} />
            <br />

            <div className="button-section">
              <Space>
                <Button
                  className="btn-login"
                  size="large"
                  type="primary"
                  onClick={handleShowHint}
                >
                  Show Hint
                </Button>
                <Button
                  className="btn-login"
                  size="large"
                  type="primary"
                  onClick={handleNext}
                >
                  {solNextText}
                </Button>
              </Space>
            </div>
            <br />
            {showSolution && (
              <Alert
                message={
                  <>
                    <span style={{ fontSize: "medium", fontStyle: "bold" }}>
                      Hint :
                    </span>
                    <br />
                    <span
                      style={{ fontSize: "medium" }}
                      dangerouslySetInnerHTML={{ __html: question?.prompt }}
                    />
                  </>
                }
                type="info"
              />
            )}

            {showImage && (
              <Alert
                message={
                  <>
                    <span style={{ fontSize: "medium", fontStyle: "bold" }}>
                      Possible Solution :
                    </span>
                    <br />
                    <center>
                      {question?.ImageUrl.map((item) => (
                        <Image key={item} width={400} src={item} />
                      ))}
                    </center>
                  </>
                }
                type="info"
              />
            )}
          </Card>
        </div>
      </AfterLoginLayout>
      <Modal
        title="Confirm Quit"
        open={isModalVisible}
        onOk={() => navigate("/selection")}
        onCancel={() => setIsModalVisible(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to quit the practice session?</p>
      </Modal>
      <Modal
        title="Watch Tutorial"
        open={OpenTut}
        onOk={() => navigate("/selection")}
        onCancel={() => setOpenTut(false)}
        okText="Yes"
        width={620}
        cancelText="No"
        footer=""
      >
        <div>
          <iframe
            className="Youtube"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/A4Lje-dpqW4?si=LQwxcCXAf3bRL-A7"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>
      <Modal
        title={isPremiumUser ? "Great Job!" : "Limit Reached!"}
        open={isModalRedirectVisible}
        onOk={() => navigate("/report")}
        onCancel={() => setIsModalRedirectVisible(false)}
        okText="Yes"
        cancelText="No"
        footer=""
      >
        <p>
          {isPremiumUser
            ? "Congratulations! You have successfully completed today's tasks. Keep up the good work!"
            : "You have reached today's usage limit."}
        </p>
        <Row justify={"space-evenly"} style={{ marginTop: "20px" }}>
          <Button
            disabled={isPremiumUser}
            className="btn-login"
            style={{
              backgroundColor: "#0156d2",
              display: isPremiumUser ? "none" : "block",
            }}
            onClick={handlePayment}
          >
            Upgrade to Pro
          </Button>
          <Button
            className="btn-login"
            style={{ backgroundColor: "#0156d2" }}
            onClick={() => navigate("/report")}
          >
            Report & Feedback
          </Button>
        </Row>
      </Modal>
    </>
  );
}

export default PracticeQuestion;
