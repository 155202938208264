import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD5vSlMHTLMCeJCy_EOC0SUtxos5LEjjpk",
  authDomain: "nift-exam.firebaseapp.com",
  databaseURL:
    "https://nift-exam-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "nift-exam",
  storageBucket: "nift-exam.appspot.com",
  messagingSenderId: "1052519452351",
  appId: "1:1052519452351:web:4a1dd99eed981c76f2400a",
  measurementId: "G-GTPHN1QJVZ",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const database = firebase.database();
const auth = getAuth(firebaseApp);
const googleAuthProvider = new GoogleAuthProvider();
const analytics = getAnalytics(firebaseApp);

export { auth, database, googleAuthProvider, analytics };
