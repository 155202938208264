import React, { useEffect, useState } from "react";
import { Typography, Card, Row, Col, message, Spin, Modal } from "antd";
import AfterLoginLayout from "../components/AfterLoginLayout";
import { useNavigate } from "react-router-dom";
import first from "../assets/1.png";
import second from "../assets/2.png";
import third from "../assets/3.png";
import cat from "../assets/cat.jpg";
import { auth, database, analytics } from "../firebase";
import { get, ref } from "firebase/database";
import TopBar from "../components/TopBar";
import { logEvent } from "firebase/analytics";

const { Title, Text } = Typography;

export default function PractiseSelection() {
  const navigate = useNavigate();
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [examType, setExamType] = useState("BDES");
  const [questionsAttempted, setQuestionsAttempted] = useState(0);
  const [catquestionsAttempted, setCATQuestionsAttempted] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const [hasOverallData, setHasOverallData] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        fetchData(currentUser);
      } else {
        navigate("/");
      }
    });
    const fetchData = async (currentUser) => {
      try {
        const premiumRef = ref(database, `Users/${currentUser.uid}/premium`);
        const examTypeRef = ref(database, `Users/${currentUser.uid}/ExamType`);
        const overallRef = ref(database, `Users/${currentUser.uid}/Overall`);

        const questionsAttemptedRef = ref(
          database,
          `Users/${currentUser.uid}/todays_question`
        );
        const CAT_questionsAttemptedRef = ref(
          database,
          `Users/${currentUser.uid}/todays_question_CAT`
        );
        const [
          premiumSnapshot,
          examTypeSnapshot,
          questionsAttemptedSnapshot,
          CAT_questionsAttemptedSnapshot,
        ] = await Promise.all([
          get(premiumRef),
          get(examTypeRef),
          get(questionsAttemptedRef),
          get(CAT_questionsAttemptedRef),
        ]);

        if (premiumSnapshot.exists()) {
          setIsPremiumUser(premiumSnapshot.val());
        }
        if (examTypeSnapshot.exists()) {
          setExamType(examTypeSnapshot.val());
        }
        if (questionsAttemptedSnapshot.exists()) {
          setQuestionsAttempted(questionsAttemptedSnapshot.val());
        }
        if (questionsAttemptedSnapshot.exists()) {
          setCATQuestionsAttempted(CAT_questionsAttemptedSnapshot.val());
        }
        const overallSnapshot = await get(overallRef);
        setHasOverallData(overallSnapshot.exists());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "Selection" });
  }, []);

  const handleMockTestClick = () => {
    setShowFeedbackModel(true);
  };

  const handlePracticeQuestionsClick = () => {
    const questionLimit = isPremiumUser ? 50 : 10;

    if (questionsAttempted >= questionLimit) {
      message.info("You have used your daily limit. Come back tomorrow.");
    } else {
      navigate("/practice-question");
    }
  };

  const handleCATPracticeQuestionsClick = () => {
    const questionLimit = isPremiumUser ? 5 : 1;
    if (catquestionsAttempted >= questionLimit) {
      message.info("You have used your daily limit. Come back tomorrow.");
    } else {
      navigate("/practice-cat");
    }
  };

  const handleReportClick = () => {
    if (!hasOverallData) {
      message.info("No report data available yet.");
      return;
    }
    navigate("/report");
  };

  if (isLoading) {
    return (
      <AfterLoginLayout>
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <Spin size="large" />
        </div>
      </AfterLoginLayout>
    );
  }

  return (
    <div style={{ height: "100vh" }}>
      {isPremiumUser ? <></> : <TopBar />}
      <AfterLoginLayout>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#2B2b2B",
            color: "white",
            height: "calc(100vh - 350px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Title className="middle-font" style={{ marginTop: "2em" }}>
            Select What you want to
          </Title>
          <Row justify="center">
            <Col xs={24} sm={20} md={12} lg={10} xl={8}>
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Card
                    className="Menu-Card"
                    style={{ backgroundColor: "#2B2b2B", cursor: "pointer" }}
                    onClick={handlePracticeQuestionsClick}
                  >
                    <Row gutter={16}>
                      <Col span={4}>
                        <img
                          src={first}
                          alt="description2"
                          height={80}
                          width={80}
                        />
                      </Col>
                      <Col span={18} offset={2} style={{ textAlign: "left" }}>
                        <Text
                          className="middle-font"
                          style={{ color: "white" }}
                        >
                          Practice Question
                        </Text>
                        <div>
                          <Text style={{ color: "white" }}>
                            Get AI-generated questions on every topic that comes
                            in the NIFT exam.
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card
                    className="Menu-Card"
                    style={{ backgroundColor: "#2B2b2B", cursor: "pointer" }}
                    onClick={handleMockTestClick}
                  >
                    <Row gutter={16}>
                      <Col span={4}>
                        <img
                          src={second}
                          alt="description2"
                          height={80}
                          width={80}
                        />
                      </Col>
                      <Col span={18} offset={2} style={{ textAlign: "left" }}>
                        <Text
                          className="middle-font"
                          style={{ color: "white" }}
                        >
                          Mock Test
                        </Text>
                        <div>
                          <Text style={{ color: "white" }}>
                            Every Sunday get ready to take mock test.
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card
                    className="Menu-Card"
                    style={{ backgroundColor: "#2B2b2B", cursor: "pointer" }}
                    onClick={handleReportClick}
                  >
                    <Row gutter={16}>
                      <Col span={4}>
                        <img
                          src={third}
                          alt="description2"
                          height={80}
                          width={80}
                        />
                      </Col>
                      <Col span={18} offset={2} style={{ textAlign: "left" }}>
                        <Text
                          className="middle-font"
                          style={{ color: "white" }}
                        >
                          Report and Feedback
                        </Text>
                        <div>
                          <Text style={{ color: "white" }}>
                            Get a detailed Analysis of your growth and
                            suggestions for improvement.
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  xs={24}
                  style={{
                    display:
                      examType === "BDES" || examType === "MDES"
                        ? "block"
                        : "none",
                  }}
                >
                  <Card
                    className="Menu-Card"
                    style={{ backgroundColor: "#2B2b2B", cursor: "pointer" }}
                    onClick={handleCATPracticeQuestionsClick}
                  >
                    <Row gutter={16}>
                      <Col span={4}>
                        <img
                          src={cat}
                          alt="description2"
                          height={80}
                          width={80}
                        />
                      </Col>
                      <Col span={18} offset={2} style={{ textAlign: "left" }}>
                        <Text
                          className="middle-font"
                          style={{ color: "white" }}
                        >
                          Creative Ability Test
                        </Text>
                        <div>
                          <Text style={{ color: "white" }}>
                            Let our AI assist you in mastering creative ability
                            test.
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </AfterLoginLayout>

      <Modal
        title="Mock test"
        open={showFeedbackModel}
        onOk={() => setShowFeedbackModel(false)}
        onCancel={() => setShowFeedbackModel(false)}
        okText="Ok"
      >
        {isPremiumUser ? (
          <p>We are Preparing your Mock test. Comeback on Sunday!</p>
        ) : (
          <p>Mock Test is Only Available for Pro Users</p>
        )}
      </Modal>
    </div>
  );
}
