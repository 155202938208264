import React, { PureComponent } from "react";
import { PieChart, Pie, Cell } from "recharts";

const RADIAN = Math.PI / 180;
const data = [
  { name: "A", value: 20, color: "#FFA28B" },
  { name: "B", value: 15, color: "#FFD33D" },
  { name: "C", value: 15, color: "#7EE787" },
];
const cx = 200;
const cy = 200;
const iR = 75;
const oR = 150;
const value = 50;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
    />,
  ];
};

export default class Example extends PureComponent {
  calculateAverage = (userData, period) => {
    const datesToConsider = this.getDatesBasedOnPeriod(period);
    let total = 0;
    let count = 0;

    datesToConsider.forEach((date) => {
      if (userData.Daily && userData.Daily[date]) {
        total += userData.Daily[date].total_question_Attempt || 0;
        count++;
      }
    });

    return count > 0 ? total / count : 0;
  };

  getDatesBasedOnPeriod = (period) => {
    const today = new Date();
    let datesToConsider = [];

    switch (period) {
      case "Today":
        datesToConsider.push(this.formatDate(today));
        break;
      case "Past Week":
        for (let i = 0; i < 7; i++) {
          let date = new Date();
          date.setDate(today.getDate() - i);
          datesToConsider.push(this.formatDate(date));
        }
        break;
      case "Past Month":
        for (let i = 0; i < 30; i++) {
          let date = new Date();
          date.setDate(today.getDate() - i);
          datesToConsider.push(this.formatDate(date));
        }
        break;
      case "Overall":
        for (let i = 0; i < 30; i++) {
          let date = new Date();
          date.setDate(today.getDate() - i);
          datesToConsider.push(this.formatDate(date));
        }
        break;
      default:
        break;
    }

    return datesToConsider;
  };

  formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  };

  render() {
    const { userData, Period } = this.props;
    const averageAttempt = this.calculateAverage(userData, Period);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <PieChart width={400} height={300}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(averageAttempt, data, cx, cy, iR, oR, "#fafafa")}
        </PieChart>
      </div>
    );
  }
}
