import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import LoginScreen from "./pages/LoginScreen";
import PractiseSelection from "./pages/PractiseSelection";
import PracticeQuestion from "./pages/PracticeQuestion";
import Report from "./pages/Report";
import Feedback from "./pages/Feedback";
import PracticeCAT from "./pages/PracticeCAT";

function App() {
  const rootElement = document.getElementById("root");

  rootElement.style.backgroundColor = "#2B2b2B";
  rootElement.style.color = "white";

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/selection" element={<PractiseSelection />} />
          <Route path="/practice-question" element={<PracticeQuestion />} />
          <Route path="/practice-cat" element={<PracticeCAT />} />
          <Route path="/report" element={<Report />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
