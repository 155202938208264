import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Radio,
  Alert,
  Space,
  Card,
  Row,
  Col,
  message,
  Spin,
  Modal,
  Button,
} from "antd";
import AfterLoginLayout from "../components/AfterLoginLayout";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import stop from "../assets/stop.png";
import { database, auth, analytics } from "../firebase";
import { ref, get, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
import "../styles/PracticeQuestion.css";
import { logEvent } from "firebase/analytics";

function PracticeQuestion() {
  const [timer, setTimer] = useState(0);
  const [question, setQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSolution, setShowSolution] = useState(false);
  const [todaysQuestions, setTodaysQuestions] = useState(0);
  const [OverallQuestions, setOverallQuestions] = useState(0);
  const [DailyQuestions, setDailyQuestions] = useState(0);
  const [lastsubAttemptedQue, setlastsubAttemptedQue] = useState(0);
  const [userId, setUserId] = useState();
  const [TodaysDate, setTodaysDate] = useState();
  const [MainTopic, setMainTopic] = useState();
  const [subTopic, setsubTopic] = useState();
  const [IsCorrect, setIsCorrect] = useState(false);
  const timerRef = useRef(null);
  const [userType, setUserType] = useState(null);
  const [user, setUser] = useState(null);
  const [questionLimit, setQuestionLimit] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalRedirectVisible, setIsModalRedirectVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
  }, [question, showSolution]);

  useEffect(() => {
    setIsNextButtonDisabled(true);
  }, [question]);

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "Practice Questions" });
  }, []);

  const fetchTodaysQuestionForUser = useCallback(
    async (userId, questionLimit) => {
      try {
        const snapshot = await get(
          ref(database, `Users/${userId}/todays_question`)
        );
        const snapshotOverall = await get(
          ref(database, `Users/${userId}/Overall/total_question_Attempt`)
        );
        const snapshotDaily = await get(
          ref(
            database,
            `Users/${userId}/Daily/${TodaysDate}/total_question_Attempt`
          )
        );
        if (snapshot.val() >= questionLimit) {
          message.info("You have used your daily limit. Come back tomorrow.");
          navigate("/selection");
        }
        setTodaysQuestions(snapshot.val() || 0);
        setOverallQuestions(snapshotOverall.val() || 0);
        setDailyQuestions(snapshotDaily.val() || 0);
      } finally {
        setIsLoading(false);
      }
    },
    [TodaysDate, navigate]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setTodaysDate(new Date().toISOString().split("T")[0].replace(/-/g, ""));
      } else {
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      const fetchUserData = async () => {
        try {
          const userRef = ref(database, `Users/${userId}`);
          const snapshot = await get(userRef);
          const userData = snapshot.val();
          if (userData) {
            setUser(userData);
            setUserType(userData.premium);
            setQuestionLimit(userData.premium ? 50 : 10);
            fetchTodaysQuestionForUser(userId, userData.premium ? 50 : 10);
          }
        } catch (error) {
          message.error(`Can Not fetch User Data`);
        } finally {
          setIsLoading(false);
        }
      };

      fetchUserData();
    }
  }, [userId]);

  const fetchQuestionForUser = useCallback(
    async (mainTopic, subtopic) => {
      const lastAttemptedRef = ref(
        database,
        `Users/${userId}/Overall/AttemptedSubtopicNumber/${subtopic}_question_attempt`
      );
      const lastAttemptedQuestionSnapshot = await get(lastAttemptedRef);
      const lastAttemptedQuestion = lastAttemptedQuestionSnapshot.val();
      setlastsubAttemptedQue(lastAttemptedQuestion);
      if (!lastAttemptedQuestion) {
        const question = await fetchQuestionBySequenceId(
          mainTopic,
          subtopic,
          1
        );
        setQuestion(question);
      } else {
        const question = await fetchQuestionBySequenceId(
          mainTopic,
          subtopic,
          lastAttemptedQuestion + 1
        );
        setQuestion(question);
      }
    },
    [userId]
  );

  const fetchNextQuestion = useCallback(async () => {
    const mainTopics = [
      "English Comprehension",
      "Analytical Ability and Logical Ability",
      "Communication Ability",
      "General Knowledge and Current Affairs",
      "Quantitative Ability",
    ];

    const topicIndex = OverallQuestions % 10;

    if (topicIndex >= 0 && topicIndex <= 4) {
      const subtopic = await fetchRandomSubtopicFromTopic(mainTopics[0]);
      setsubTopic(subtopic);
      setMainTopic(mainTopics[0]);
      fetchQuestionForUser(mainTopics[0], subtopic);
    } else if (topicIndex === 5) {
      const subtopic = await fetchRandomSubtopicFromTopic(mainTopics[1]);
      setsubTopic(subtopic);
      setMainTopic(mainTopics[1]);
      fetchQuestionForUser(mainTopics[1], subtopic);
    } else if (topicIndex === 6) {
      const subtopic = await fetchRandomSubtopicFromTopic(mainTopics[2]);
      setsubTopic(subtopic);
      setMainTopic(mainTopics[2]);
      fetchQuestionForUser(mainTopics[2], subtopic);
    } else if (topicIndex === 7) {
      const subtopic = await fetchRandomSubtopicFromTopic(mainTopics[3]);
      setsubTopic(subtopic);
      setMainTopic(mainTopics[3]);
      fetchQuestionForUser(mainTopics[3], subtopic);
    } else if (topicIndex >= 8 && topicIndex <= 9) {
      const subtopic = await fetchRandomSubtopicFromTopic(mainTopics[4]);
      setsubTopic(subtopic);
      setMainTopic(mainTopics[4]);
      fetchQuestionForUser(mainTopics[4], subtopic);
    }
  }, [OverallQuestions, fetchQuestionForUser]);

  useEffect(() => {
    fetchNextQuestion();
  }, [fetchNextQuestion]);

  const fetchQuestionBySequenceId = async (
    mainTopic,
    subtopicName,
    sequenceId
  ) => {
    const questionSnapshot = await database
      .ref(`Questions/${mainTopic}/${subtopicName}`)
      .orderByChild("sequence_id")
      .equalTo(sequenceId)
      .once("value");
    const questionData = questionSnapshot.val();
    if (!questionData) {
      fetchRandomSubtopicFromTopic(MainTopic);
      const newSubtopic = await fetchRandomSubtopicFromTopic(mainTopic);
      return await fetchQuestionBySequenceId(
        mainTopic,
        newSubtopic,
        lastsubAttemptedQue + 1
      );
    }
    const key = Object.keys(questionData)[0];
    return questionData[key];
  };

  const fetchRandomSubtopicFromTopic = async (mainTopic) => {
    const subtopicsSnapshot = await database
      .ref(`Questions/${mainTopic}`)
      .once("value");
    const subtopicNames = Object.keys(subtopicsSnapshot.val() || {});
    const randomIndex = Math.floor(Math.random() * subtopicNames.length);
    return subtopicNames[randomIndex];
  };

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [timer]);

  const stopTimer = () => {
    clearInterval(timerRef.current);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = () => {
    if (selectedOption !== null) {
      if (selectedOption === question.Answer) {
        setIsCorrect(true);
        UpdateTheMarksOfSubtopic(subTopic, 1);
      } else {
        UpdateTheMarksOfSubtopic(subTopic, -1);
        setIsCorrect(false);
      }
      setIsNextButtonDisabled(false);
      updateTimeAvgForSubtopic();
      setShowSolution(true);
    } else {
      message.error("Please Select the Answer!");
    }
  };

  const updateTimeAvgForSubtopic = () => {
    stopTimer();
    const dataRef = ref(
      database,
      `Users/${userId}/Overall/AvgTimeOfSubtopic/${subTopic}_time`
    );
    get(dataRef)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (currentData === null) {
          return set(dataRef, timer);
        }
        return set(dataRef, ((currentData || 0) + timer) / 2);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const dataRefDaily = ref(
      database,
      `Users/${userId}/Daily/${TodaysDate}/AvgTimeOfSubtopic/${subTopic}_time`
    );
    get(dataRefDaily)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (currentData === null) {
          return set(dataRefDaily, timer);
        }
        return set(dataRefDaily, ((currentData || 0) + timer) / 2);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const UpdateTheMarksOfSubtopic = (subTopic, value) => {
    const dataRef = ref(
      database,
      `Users/${userId}/Overall/MarksOfSubtopic/${subTopic}_mark`
    );
    get(dataRef)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (value < 0 && (!currentData || currentData <= 0)) {
          return set(dataRef, 0);
        }
        return set(dataRef, (currentData || 0) + value);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const dataRefDaily = ref(
      database,
      `Users/${userId}/Daily/${TodaysDate}/MarksOfSubtopic/${subTopic}_mark`
    );
    get(dataRefDaily)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (value < 0 && (!currentData || currentData <= 0)) {
          return set(dataRefDaily, 0);
        }
        return set(dataRefDaily, (currentData || 0) + value);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleNext = () => {
    if (showSolution === false) {
      message.error("Click on Submit!");
      return;
    }
    if (todaysQuestions + 1 >= questionLimit) {
      database
        .ref(
          `Users/${userId}/Overall/AttemptedSubtopicNumber/${subTopic}_question_attempt`
        )
        .set(question.sequence_id);

      const dataRef = ref(
        database,
        `Users/${userId}/Daily/${TodaysDate}/AttemptedSubtopicNumber/${subTopic}_question_attempt`
      );
      get(dataRef)
        .then((snapshot) => {
          const currentData = snapshot.val();
          if (!currentData || currentData <= 0) {
            return set(dataRef, 0);
          }
          return set(dataRef, (currentData || 0) + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      database.ref(`Users/${userId}/todays_question`).set(todaysQuestions + 1);
      database
        .ref(`Users/${userId}/Overall/total_question_Attempt`)
        .set(OverallQuestions + 1);
      database
        .ref(`Users/${userId}/Daily/${TodaysDate}/total_question_Attempt`)
        .set(DailyQuestions + 1);
      setIsModalRedirectVisible(true);
      return;
    }

    setSelectedOption(null);
    setIsCorrect(null);
    database
      .ref(
        `Users/${userId}/Overall/AttemptedSubtopicNumber/${subTopic}_question_attempt`
      )
      .set(question.sequence_id);

    const dataRef = ref(
      database,
      `Users/${userId}/Daily/${TodaysDate}/AttemptedSubtopicNumber/${subTopic}_question_attempt`
    );
    get(dataRef)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (!currentData) {
          return set(dataRef, 1);
        }
        return set(dataRef, currentData + 1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowSolution(false);
    const updatedQuestionsCount = todaysQuestions + 1;
    const updatedOverallQuestionCount = OverallQuestions + 1;
    const updateDailyQuestionCount = DailyQuestions + 1;
    setOverallQuestions(updatedOverallQuestionCount);
    setTodaysQuestions(updatedQuestionsCount);
    setDailyQuestions(updateDailyQuestionCount);
    setTimer(0);
    database.ref(`Users/${userId}/todays_question`).set(updatedQuestionsCount);
    database
      .ref(`Users/${userId}/Overall/total_question_Attempt`)
      .set(updatedOverallQuestionCount);
    database
      .ref(`Users/${userId}/Daily/${TodaysDate}/total_question_Attempt`)
      .set(updateDailyQuestionCount);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  }

  const showConfirmModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePaymentSuccess = async (response) => {
    const paymentRecord = {
      paymentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      signature: response.razorpay_signature,
      createdAt: new Date().toISOString(),
    };

    try {
      const userId = user.uid;
      await database.ref(`Payments/${userId}`).push(paymentRecord);
      await database.ref(`Users/${userId}`).update({ premium: true });
      navigate("/selection");
    } catch (error) {
      console.error("Error saving payment record:", error);
    }
  };

  const loadRazorpay = (details) => {
    const options = {
      key: "rzp_live_bMK7LvizPK6oVa",
      amount: details.amount,
      currency: details.currency,
      name: "AI NIFT EXAM PREP",
      description: "Payment for Nift Exam Prep",
      order_id: details.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: user.displayName,
        email: user.email,
        contact: "",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const processPayment = async (paymentData) => {
    try {
      const response = await fetch(
        "https://asia-southeast1-nift-exam.cloudfunctions.net/processPayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const orderDetails = await response.json();
      loadRazorpay(orderDetails);
    } catch (error) {
      console.error("Error during the payment process:", error);
    }
  };

  const handlePayment = () => {
    const paymentDetails = {
      amount: 49900,
    };
    processPayment(paymentDetails);
  };

  return (
    <AfterLoginLayout>
      {isLoading ? (
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Button
            type="primary"
            className="btn-login back"
            onClick={showConfirmModal}
            style={{
              marginLeft: "45px",
              marginTop: "15px",
            }}
          >
            Back
          </Button>
          <div className="app">
            <Row className="timer-section" gutter={[20, 0]}>
              <Col xs={18} md={20} lg={18}>
                <Card className="Main-timer-card">
                  <Row>
                    <Space>
                      <Card className="Inner-timer-card">
                        <span>
                          {todaysQuestions === null || 0 ? 1 : todaysQuestions}/
                          {questionLimit}
                        </span>
                      </Card>
                      <span>Time: {formatTime(timer)}</span>
                    </Space>
                  </Row>
                </Card>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={6}
                className="stop"
                style={{ marginTop: "0.2rem" }}
              >
                <img
                  src={stop}
                  width={30}
                  height={30}
                  alt="Stop"
                  style={{ cursor: "pointer" }}
                  onClick={showConfirmModal}
                />
              </Col>
            </Row>
            <Card className="question-section" bordered={false}>
              <span className="MainTopicText">Topic: {MainTopic}</span>
              <br />
              <span
                className="labelText"
                style={{
                  display:
                    MainTopic === "English Comprehension" ? "block" : "none",
                }}
              >
                Passage:
              </span>
              <span dangerouslySetInnerHTML={{ __html: question?.passage }} />
              <br />
              <span className="labelText">Question:</span>
              <span dangerouslySetInnerHTML={{ __html: question?.question }} />
              <br />
              <span className="labelText"> Options:</span>
              <br />
              <Radio.Group
                disabled={showSolution}
                onChange={handleOptionChange}
                value={selectedOption}
              >
                <Radio
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionA}
                  value="A"
                >
                  <br />
                  <span>A)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionA }}
                  />
                  <br />
                </Radio>
                <Radio
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionB}
                  value="B"
                >
                  <br />
                  <span>B)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionB }}
                  />
                  <br />
                </Radio>
                <Radio
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionC}
                  value="C"
                >
                  <br />
                  <span>C)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionC }}
                  />
                  <br />
                </Radio>
                <Radio
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionD}
                  value="D"
                >
                  <br />
                  <span>D)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionD }}
                  />
                  <br />
                </Radio>
              </Radio.Group>
              <br />
              <br />

              <div className="button-section">
                <Space>
                  <Button
                    className="btn-login"
                    size="large"
                    type="primary"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                  <Button
                    className="btn-login"
                    size="large"
                    type="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Space>
              </div>
              <br />
              <div style={{ display: showSolution ? "block" : "none" }}>
                <CloseCircleTwoTone
                  style={{
                    fontSize: "42px",
                    display: IsCorrect ? "none" : "flex",
                  }}
                  twoToneColor="#FF3131"
                />
                <CheckCircleTwoTone
                  style={{
                    fontSize: "42px",
                    display: IsCorrect ? "flex" : "none",
                  }}
                  twoToneColor="#23FD00"
                />
              </div>
              <br />
              {showSolution && (
                <Alert
                  message={
                    <>
                      <span style={{ fontSize: "medium", fontStyle: "bold" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Selected Answer :{" "}
                        </span>
                        {selectedOption}
                      </span>
                      <br />
                      <span style={{ fontSize: "medium", fontStyle: "bold" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Correct Answer :{" "}
                        </span>{" "}
                        {question?.Answer}
                      </span>
                      <br />
                      <span style={{ fontSize: "medium", fontStyle: "bold" }}>
                        Solution :
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "medium" }}
                        dangerouslySetInnerHTML={{ __html: question?.solution }}
                      />
                    </>
                  }
                  type="info"
                />
              )}
            </Card>
          </div>
        </>
      )}
      <Modal
        title="Confirm Quit"
        open={isModalVisible}
        onOk={() => navigate("/selection")}
        onCancel={() => setIsModalVisible(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to quit the practice session?</p>
      </Modal>
      <Modal
        title={userType ? "Great Job!" : "Limit Reached!"}
        open={isModalRedirectVisible}
        onOk={() => navigate("/report")}
        onCancel={() => setIsModalRedirectVisible(false)}
        okText="Yes"
        cancelText="No"
        footer=""
      >
        <p>
          {userType
            ? "Congratulations! You have successfully completed today's tasks. Keep up the good work!"
            : "You have reached today's usage limit."}
        </p>
        <Row justify={"space-evenly"} style={{ marginTop: "20px" }}>
          <Button
            disabled={userType}
            className="btn-login"
            style={{
              backgroundColor: "#0156d2",
              display: userType ? "none" : "block",
            }}
            onClick={handlePayment}
          >
            Upgrade to Pro
          </Button>
          <Button
            className="btn-login"
            style={{ backgroundColor: "#0156d2" }}
            onClick={() => navigate("/report")}
          >
            Report & Feedback
          </Button>
        </Row>
      </Modal>
    </AfterLoginLayout>
  );
}

export default PracticeQuestion;
