import { Button, Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../firebase";
import { get, ref } from "firebase/database";

const TopBar = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePaymentSuccess = async (response) => {
    const paymentRecord = {
      paymentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      signature: response.razorpay_signature,
      createdAt: new Date().toISOString(),
    };

    try {
      const userId = user.uid;
      await database.ref(`Payments/${userId}`).push(paymentRecord);
      await database.ref(`Users/${userId}`).update({ premium: true });
      navigate("/selection");
    } catch (error) {
      console.error("Error saving payment record:", error);
    }
  };

  const loadRazorpay = (details) => {
    const options = {
      key: "rzp_live_bMK7LvizPK6oVa",
      amount: details.amount,
      currency: details.currency,
      name: "AI NIFT EXAM PREP",
      description: "Payment for Nift Exam Prep",
      order_id: details.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: user.displayName,
        email: user.email,
        contact: "",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const processPayment = async (paymentData) => {
    try {
      const response = await fetch(
        "https://asia-southeast1-nift-exam.cloudfunctions.net/processPayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const orderDetails = await response.json();
      loadRazorpay(orderDetails);
    } catch (error) {
      console.error("Error during the payment process:", error);
    }
  };

  const handlePayment = () => {
    const paymentDetails = {
      amount: 49900,
    };
    processPayment(paymentDetails);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        fetchData(currentUser);
      }
    });

    const fetchData = async (currentUser) => {
      try {
        const photoRef = ref(database, `Users/${currentUser?.uid}`);
        const userTypeRef = ref(database, `Users/${currentUser?.uid}/premium`);

        const photoSnapshot = await get(photoRef);
        const userTypeSnapshot = await get(userTypeRef);

        if (photoSnapshot.exists()) {
          setUser(photoSnapshot.val());
        }
        if (userTypeSnapshot.exists()) {
          setUserType(userTypeSnapshot.val());
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    return () => unsubscribe();
  }, []);

  return (
    <Row
      style={{
        width: "100%",
        backgroundColor: "#fafafa",
        padding: "10px 10px 10px 10px",
      }}
    >
      <Col span={24} className="content-center">
        {userType ? (
          <span style={{ color: "black" }}>Your Account upgraded</span>
        ) : (
          <Space size={16}>
            <span style={{ color: "black" }}>
              Pro users data show a 90% performance boost in NIFT exams.
            </span>
            <Button
              disabled={userType}
              className="btn-login"
              style={{ backgroundColor: "#0156d2" }}
              onClick={handlePayment}
            >
              Upgrade to Pro
            </Button>
          </Space>
        )}
      </Col>
    </Row>
  );
};

export default TopBar;
