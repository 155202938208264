import React, { useEffect, useState } from "react";
import SwitchButton from "../components/SwitchButton";
import Donut from "../components/Donut";
import StackBar from "../components/StackBar";
import PieChartWithNeedle from "../components/PieChartWithNeedle";
import LineChart from "../components/LineChart";
import "../App.css";
import {
  Row,
  Flex,
  Card,
  Select,
  Divider,
  Col,
  Button,
  Spin,
  message,
} from "antd";
import AfterLoginLayout from "../components/AfterLoginLayout";
import { useNavigate } from "react-router-dom";
import "../styles/Report.css";
import { database, auth, analytics } from "../firebase";
import { get, ref } from "firebase/database";
import { logEvent } from "firebase/analytics";

export default function Report() {
  const { Meta } = Card;
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState("Today");

  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "Report" });
  }, []);

  useEffect(() => {
    setSelectedPeriod("Today");
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoading(true);
        const fetchUserData = async () => {
          try {
            const userRef = ref(database, `Users/${user.uid}`);
            const snapshot = await get(userRef);
            const userData = snapshot.val();
            if (userData) {
              setUser(userData);
            }
          } catch (error) {
            message.error(`Can Not fetch User Data`);
          } finally {
            setIsLoading(false);
          }
        };

        fetchUserData();
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChange = (value) => {
    setSelectedPeriod(value);
  };

  const goBack = () => {
    navigate("/selection");
  };

  if (isLoading) {
    return (
      <AfterLoginLayout>
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <Spin size="large" />
        </div>
      </AfterLoginLayout>
    );
  }

  return (
    <AfterLoginLayout>
      <div>
        <Button
          type="primary"
          className="btn-login"
          onClick={goBack}
          style={{
            marginLeft: "20px",
            marginTop: "20px",
            position: "relative",
          }}
        >
          Back
        </Button>
        <SwitchButton />
        <Flex justify="space-evenly">
          <Select
            defaultValue="Today"
            style={{ width: 150 }}
            placeholder="Select a Date for Analytics"
            onChange={handleChange}
            options={[
              { value: "Today", label: "Today" },
              { value: "Past Week", label: "Past Week" },
              { value: "Past Month", label: "Past Month" },
              { value: "Overall", label: "Overall" },
            ]}
          />
        </Flex>
        <Divider />
        <Row justify={"space-around"} gutter={[0, 0, 0, 32]}>
          <Col xxl={5} xl={7} lg={9} md={13} sm={23} xs={23}>
            <Card
              hoverable="true"
              className="report-card"
              title="Overall Performance"
              bordered={false}
              style={{
                width: 400,
                height: "fit-content",
                backgroundColor: "black",
                marginBottom: "50px",
              }}
            >
              <PieChartWithNeedle userData={user} Period={selectedPeriod} />
              <Meta description="This chart shows your overall growth. The more regular you are the better results you will get." />
            </Card>
          </Col>
          <Col xxl={5} xl={7} lg={9} md={13} sm={23} xs={23}>
            <Card
              hoverable="true"
              className="report-card"
              title="Topic Analysis"
              bordered={false}
              style={{
                width: 400,
                height: "fit-content",
                backgroundColor: "black",
                marginBottom: "50px",
              }}
            >
              <Donut userData={user} Period={selectedPeriod} />
              <Meta description="Analyze your time spent on each NIFT exam topic. Optimize your study approach accordingly." />
              <br />
              <Meta
                description={
                  <p>
                    <b>CA</b>: Communication Ability ;<b>A & LA</b> : Analytical
                    Ability and Logical Ability ; <b>EC</b> : English
                    Comprehension; <b>GK & CA</b> : General Knowledge and
                    Current Affairs ; <b>QA</b>: Quantitative Ability
                  </p>
                }
              />
            </Card>
          </Col>
          <Col xxl={5} xl={7} lg={9} md={13} sm={23} xs={23}>
            <Card
              hoverable="true"
              className="report-card"
              title="Questions Attempted"
              bordered={false}
              style={{
                width: 400,
                height: "fit-content",
                backgroundColor: "black",
                marginBottom: "50px",
              }}
            >
              <LineChart userData={user} Period={selectedPeriod} />
              <Meta description="Understand how many questions you have attempted. Consistent engagement contribute significantly to achieving optimal results." />
            </Card>
          </Col>
          <Col xxl={5} xl={7} lg={9} md={13} sm={23} xs={23}>
            <Card
              hoverable="true"
              className="report-card"
              title="Error Rate"
              bordered={false}
              style={{
                width: 400,
                height: "fit-content",
                backgroundColor: "black",
                marginBottom: "50px",
              }}
            >
              <StackBar userData={user} Period={selectedPeriod} />
              <Meta
                description={
                  <p>
                    Soving more question is great but understanding how to
                    control <b> error rate</b> also becomes important. Be
                    carefull in solving topics with high error rate.
                  </p>
                }
              />
            </Card>
          </Col>
        </Row>
        {/* <div className="exam-text-selection">
          <Text className="middle-font">niftexam.com</Text>
        </div> */}
      </div>
    </AfterLoginLayout>
  );
}
