import React, { useEffect } from "react";
import { Row, Col, Typography } from "antd";
import "./../styles/LoginScreen.css";
import StepScreen from "../components/StepScreen";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

const { Title } = Typography;

export default function LoginScreen() {
  useEffect(() => {
    logEvent(analytics, "page_view", { title: "Log In" });
  }, []);
  return (
    <div>
      <Row gutter={16}>
        <Col className="first-part" xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row className="Main-text">
            <Col span={24}>
              <Title className="main-font">NIFT Exam</Title>
              <Title className="secondary-font">
                AI-powered platform for NIFT preparation
              </Title>
            </Col>
          </Row>
        </Col>
        <Col className="second-part" xs={24} sm={24} md={12} lg={12} xl={12}>
          <StepScreen />
        </Col>
      </Row>
    </div>
  );
}
