import React from "react";
import SwitchButton from "../components/SwitchButton";
import { Row, Col, Typography, Card } from "antd";
import { useNavigate } from "react-router-dom";
import AfterLoginLayout from "../components/AfterLoginLayout";

export default function Feedback() {
  const { Text } = Typography;
  const arr = [0, 1, 2];
  const navigate = useNavigate();

  return (
    <AfterLoginLayout>
      <div>
        <SwitchButton />
        <div style={{ marginTop: "2em" }}>
          {arr.map((item) => {
            return (
              <Row style={{ margin: "4em" }}>
                <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 4 }}>
                  <Card
                    className="Menu-Card"
                    style={{ backgroundColor: "#2B2b2B", cursor: "pointer" }}
                    onClick={() => navigate("/practice-question")}
                  >
                    <Row gutter={16}>
                      <Col span={22} offset={2} style={{ textAlign: "left" }}>
                        <Text
                          className="middle-font"
                          style={{ color: "white" }}
                        >
                          Feedback #1
                        </Text>
                        <div>
                          <Text style={{ color: "white" }}>
                            Try solving more than 50 questions everyday from the
                            practice section. It will help you improve your
                            understanding.
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 3 }}>
                  <Card
                    className="Menu-Card"
                    style={{ backgroundColor: "#2B2b2B", cursor: "pointer" }}
                    onClick={() => navigate("/practice-question")}
                  >
                    <Row gutter={16}>
                      <Col span={22} offset={2} style={{ textAlign: "left" }}>
                        <Text
                          className="middle-font"
                          style={{ color: "white" }}
                        >
                          Feedback #1
                        </Text>
                        <div>
                          <Text style={{ color: "white" }}>
                            Try solving more than 50 questions everyday from the
                            practice section. It will help you improve your
                            understanding.
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </AfterLoginLayout>
  );
}
