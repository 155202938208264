import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function StackBar({ userData, Period }) {
  const data = [
    {
      mainTopic: "Analytical and Logical Ability",
      Correct: 0,
      Incorrect: 0,
    },
    {
      mainTopic: "English Comprehension",
      Correct: 0,
      Incorrect: 0,
    },
    {
      mainTopic: "Communication Ability",
      Correct: 0,
      Incorrect: 0,
    },
    {
      mainTopic: "General Knowledge",
      Correct: 0,
      Incorrect: 0,
    },
    {
      mainTopic: "Quantitative Ability",
      Correct: 0,
      Incorrect: 0,
    },
  ];

  const [ChartData, setChartData] = useState(data);

  const mappings = {
    "Age-Related Problems": "Analytical and Logical Ability",
    "Analytical Puzzles": "Analytical and Logical Ability",
    "Blood Relations": "Analytical and Logical Ability",
    "Logical Ability": "Analytical and Logical Ability",
    "Other LR": "Analytical and Logical Ability",
    Comprehension: "English Comprehension",
    Antonyms: "Communication Ability",
    "Correct spellings": "Communication Ability",
    "Figurative Language": "Communication Ability",
    "Idioms and phrases": "Communication Ability",
    "Metaphorical Meanings": "Communication Ability",
    "One word substitutes": "Communication Ability",
    Plural: "Communication Ability",
    Singular: "Communication Ability",
    Synonyms: "Communication Ability",
    "Words with corresponding meanings": "Communication Ability",
    "Words with phrases": "Communication Ability",
    "Creative Ability Test": "Creative Ability Test",
    "General Knowledge": "General Knowledge",
    "Current Affairs": "General Knowledge",
    Addition: "Quantitative Ability",
    Average: "Quantitative Ability",
    "Boats and Streams": "Quantitative Ability",
    Distance: "Quantitative Ability",
    Division: "Quantitative Ability",
    Fractions: "Quantitative Ability",
    Multiplication: "Quantitative Ability",
    Percentage: "Quantitative Ability",
    Probability: "Quantitative Ability",
    "Rate of Interest": "Quantitative Ability",
    "Ratio and Proportion": "Quantitative Ability",
    Statistics: "Quantitative Ability",
    "Work and Task": "Quantitative Ability",
  };

  const transformDataForChart = async (UserData, mappings, period) => {
    let dataForTotalNumber = {};
    let dataForMarks = {};

    const today = new Date();
    let datesToConsider = [];

    switch (period) {
      case "Today":
        const formattedToday = today
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "");
        dataForTotalNumber =
          UserData?.Daily?.[formattedToday]?.AttemptedSubtopicNumber || {};
        dataForMarks = UserData?.Daily?.[formattedToday]?.MarksOfSubtopic || {};
        break;
      case "Past Week":
      case "Past Month":
        const dayCount = period === "Past Week" ? 7 : 30;
        for (let i = 0; i < dayCount; i++) {
          let date = new Date();
          date.setDate(today.getDate() - i);
          const formattedDate = date
            .toISOString()
            .split("T")[0]
            .replace(/-/g, "");
          datesToConsider.push(formattedDate);
        }
        datesToConsider.forEach((date) => {
          const dayData = UserData?.Daily?.[date];
          if (dayData) {
            for (const key in dayData.AttemptedSubtopicNumber) {
              dataForTotalNumber[key] =
                (dataForTotalNumber[key] || 0) +
                dayData.AttemptedSubtopicNumber[key];
            }
            for (const key in dayData.MarksOfSubtopic) {
              dataForMarks[key] =
                (dataForMarks[key] || 0) + dayData.MarksOfSubtopic[key];
            }
          }
        });
        break;
      case "Overall":
        dataForTotalNumber = UserData?.Overall?.AttemptedSubtopicNumber || {};
        dataForMarks = UserData?.Overall?.MarksOfSubtopic || {};

        break;
      default:
        break;
    }
    const combinedData = {};

    for (const key in dataForTotalNumber) {
      const subtopic = key.split("_question_attempt")[0];
      const mainTopic = mappings[subtopic];
      if (!combinedData[mainTopic]) {
        combinedData[mainTopic] = { Attempted: 0, Correct: 0, Incorrect: 0 };
      }
      combinedData[mainTopic].Attempted += dataForTotalNumber[key];
    }
    for (const key in dataForMarks) {
      const subtopic = key.split("_mark")[0];
      const mainTopic = mappings[subtopic];
      const correct = dataForMarks[key];
      if (!combinedData[mainTopic]) {
        combinedData[mainTopic] = { Attempted: 0, Correct: 0, Incorrect: 0 };
      }
      combinedData[mainTopic].Correct += correct;
      combinedData[mainTopic].Incorrect =
        combinedData[mainTopic].Attempted - combinedData[mainTopic].Correct;
    }

    const chartData = Object.keys(combinedData).map((mainTopic) => ({
      mainTopic,
      ...combinedData[mainTopic],
    }));
    return chartData;
  };

  useEffect(() => {
    async function fetchdata() {
      const newData = await transformDataForChart(userData, mappings, Period);
      return newData;
    }
    fetchdata()
      .then((finaldata) => {
        setChartData(finaldata);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [Period, userData]);

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          data={ChartData.length > 0 ? ChartData : data}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 50, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="mainTopic" type="category" />
          <Tooltip />
          <Legend />
          <Bar dataKey="Correct" stackId="a" fill="#8884d8" />
          <Bar dataKey="Incorrect" stackId="a" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
