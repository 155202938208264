import React, { useEffect, useState } from "react";
import {
  Button,
  Steps,
  Typography,
  Row,
  Col,
  Select,
  Form,
  Input,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { auth, googleAuthProvider } from "../firebase.js";
import { signInWithPopup } from "firebase/auth";
import { database } from "../firebase";
import "./../styles/StepScreen.css";
import { ref, get } from "firebase/database";

const { Title } = Typography;

export default function StepScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const emailRef = ref(database, `Users/${user?.uid}`);
        get(emailRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              navigate("/selection");
            }
          })
          .catch((error) => {
            console.error("Error checking user email in database:", error);
          });
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const steps = [
    {
      title: "First",
      content: "First-content",
    },
    {
      title: "Second",
      content: "Second-content",
    },
    // {
    //   title: "Last",
    //   content: "Last-content",
    // },
  ];

  const [current, setCurrent] = useState(0);
  const [name, setName] = useState();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState();
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const next = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;
      if (user) {
        setUser(user);
        setName(user.displayName);
        const userDataNew = {
          name: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          photoUrl: user.photoURL,
          premium: false,
        };
        setUserData(userDataNew);
        setCurrent(current + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const nextpay = async () => {
    try {
      await database.ref(`Users/${user?.uid}`).update(userData);
      navigate("/selection");
    } catch (error) {
      console.error("Error To save");
    }
    setCurrent(current + 1);
  };
  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const handleChange = (value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      ExamType: value,
    }));
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePaymentSuccess = async (response) => {
    const paymentRecord = {
      paymentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      signature: response.razorpay_signature,
      createdAt: new Date().toISOString(),
    };

    try {
      const userId = user.uid;
      await database.ref(`Payments/${userId}`).push(paymentRecord);
      await database.ref(`Users/${userId}`).update({ premium: true });
      navigate("/selection");
    } catch (error) {
      console.error("Error saving payment record:", error);
    }
  };

  const loadRazorpay = (details) => {
    const options = {
      key: "rzp_live_bMK7LvizPK6oVa",
      amount: details.amount,
      currency: details.currency,
      name: "AI NIFT EXAM PREP",
      description: "Payment for Nift Exam Prep",
      order_id: details.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: user.displayName,
        email: user.email,
        contact: "",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const processPayment = async (paymentData) => {
    try {
      const response = await fetch(
        "https://asia-southeast1-nift-exam.cloudfunctions.net/processPayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const orderDetails = await response.json();
      loadRazorpay(orderDetails);
    } catch (error) {
      console.error("Error during the payment process:", error);
    }
  };

  const handlePayment = () => {
    const paymentDetails = {
      amount: 49900,
    };

    processPayment(paymentDetails);
  };

  const renderContent = () => {
    switch (current) {
      case 0:
        return (
          <Row>
            <Space align="center" direction="vertical" size={50}>
              <Title style={{ color: "#E4E2DD" }} className="responsive-title">
                Create Your Account or Login
              </Title>
              <div className="content-center" style={{ marginTop: "2em" }}>
                <Button
                  className="btn-login"
                  size="large"
                  type="primary"
                  onClick={() => next()}
                >
                  Sign in with Google
                </Button>
              </div>
            </Space>
          </Row>
        );
      case 1:
        return (
          <Row>
            <Col>
              <Title
                style={{ color: "#E4E2DD", textAlign: "center" }}
                className="responsive-title"
              >
                Your Details
              </Title>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    size="large"
                    defaultValue={name}
                  />
                </Form.Item>

                <Form.Item
                  name="exam_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select exam type!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select your Exam Type"
                    style={{
                      width: 300,
                      backgroundColor: "#2b2b2b",
                      color: "white",
                      borderColor: "white",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#fafafa",
                      color: "white",
                    }}
                    onChange={handleChange}
                    options={[
                      { value: "BDES", label: "BDES" },
                      { value: "MDES", label: "MDES" },
                      { value: "BFTECH", label: "BFTECH" },
                      { value: "MFTECH", label: "MFTECH" },
                      { value: "MFM", label: "MFM" },
                    ]}
                  />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    className="btn-login"
                    size="large"
                    type="primary"
                    onClick={() => nextpay()}
                  >
                    Finish
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        );
      case 2:
        return (
          <div>
            <Title
              style={{ color: "#E4E2DD" }}
              level={2}
              className="responsive-title"
            >
              Start your Journey!
            </Title>
            <Title
              style={{ color: "#E4E2DD" }}
              level={4}
              className="responsive-title"
            >
              AI-powered platform for NIFT that gets you to be in top raking.
            </Title>
            <Title style={{ color: "#E4E2DD" }} className="pay-font">
              ₹499/month
            </Title>
            <Row justify={"space-evenly"} style={{ marginTop: "20px" }}>
              <Button
                className="btn-login"
                size="large"
                type="primary"
                onClick={handlePayment}
              >
                Pay Now
              </Button>
              <Button
                className="btn-login"
                size="large"
                type="primary"
                onClick={() => navigate("/selection")}
              >
                Try Free
              </Button>
            </Row>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* <Steps className="Steeper" current={current} items={items} /> */}
      <Row justify="center">{renderContent()}</Row>
    </>
  );
}
