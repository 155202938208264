import { Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const getIntroOfPage = (label) => {
  if (label === "Page A") {
    return "Page A is about men's clothing";
  }
  if (label === "Page B") {
    return "Page B is about women's dress";
  }
  if (label === "Page C") {
    return "Page C is about women's bag";
  }
  if (label === "Page D") {
    return "Page D is about household goods";
  }
  if (label === "Page E") {
    return "Page E is about food";
  }
  if (label === "Page F") {
    return "Page F is about baby food";
  }
  return "";
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
        <p className="intro">{getIntroOfPage(label)}</p>
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  }

  return null;
};

export default function StackBar({ userData, Period }) {
  const data = [
    { name: "QA", seconds: 0.0 },
    { name: "GK & CA", seconds: 0.0 },
    { name: "CA", seconds: 0.0 },
    { name: "A & LA", seconds: 0.0 },
  ];

  const [ChartData, setChartData] = useState(data);

  const mapSubtopicToTopic = (subtopic) => {
    const mappings = {
      "Age-Related Problems": "A & LA",
      "Analytical Puzzles": "A & LA",
      "Blood Relations": "A & LA",
      "Logical Ability": "A & LA",
      "Other LR": "A & LA",
      Comprehension: "EC",
      Antonyms: "CA",
      "Correct spellings": "CA",
      "Figurative Language": "CA",
      "Idioms and phrases": "CA",
      "Metaphorical Meanings": "CA",
      "One word substitutes": "CA",
      Plural: "CA",
      Singular: "CA",
      Synonyms: "CA",
      "Words with corresponding meanings": "CA",
      "Words with phrases": "CA",
      "Creative Ability Test": "CAT",
      "General Knowledge": "GK & CA",
      "Current Affairs": "GK & CA",
      Addition: "QA",
      Average: "QA",
      "Boats and Streams": "QA",
      Distance: "QA",
      Division: "QA",
      Fractions: "QA",
      Multiplication: "QA",
      Percentage: "QA",
      Probability: "QA",
      "Rate of Interest": "QA",
      "Ratio and Proportion": "QA",
      Statistics: "QA",
      "Work and Task": "QA",
    };

    return mappings[subtopic.split("_")[0]] || "Other";
  };

  const fetchAndReshapeData = useCallback(async (UserData, period) => {
    try {
      const today = new Date();
      let datesToConsider = [];

      switch (period) {
        case "Today":
          datesToConsider.push(
            today.toISOString().split("T")[0].replace(/-/g, "")
          );
          break;
        case "Past Week":
          for (let i = 0; i < 7; i++) {
            let date = new Date();
            date.setDate(today.getDate() - i);
            datesToConsider.push(
              date.toISOString().split("T")[0].replace(/-/g, "")
            );
          }
          break;
        case "Past Month":
          for (let i = 0; i < 30; i++) {
            let date = new Date();
            date.setDate(today.getDate() - i);
            datesToConsider.push(
              date.toISOString().split("T")[0].replace(/-/g, "")
            );
          }
          break;
        case "Overall":
          datesToConsider = [UserData.Overall]; // Assuming Overall has the format required
          break;
        default:
          break;
      }
      const mainTopicTimes = {};

      datesToConsider.forEach((date) => {
        const data =
          period === "Overall"
            ? date.AvgTimeOfSubtopic
            : UserData?.Daily?.[date]?.AvgTimeOfSubtopic;

        if (data) {
          Object.keys(data).forEach((key) => {
            const mainTopic = mapSubtopicToTopic(key);
            const time = data[key];
            if (!mainTopicTimes[mainTopic]) {
              mainTopicTimes[mainTopic] = { totalTime: 0, count: 0 };
            }
            mainTopicTimes[mainTopic].totalTime += time;
            mainTopicTimes[mainTopic].count += 1;
          });
        } else {
          return [];
        }
      });

      const reshapedData = Object.keys(mainTopicTimes).map((mainTopic) => {
        const { totalTime, count } = mainTopicTimes[mainTopic];
        return { name: mainTopic, seconds: count > 0 ? totalTime / count : 0 };
      });

      return reshapedData;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchAndReshapeData(userData, Period).then((data) => {
      setChartData(data);
    });
  }, [fetchAndReshapeData, userData, Period]);

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          data={ChartData.length > 0 ? ChartData : data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="seconds" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
