import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default class Example extends PureComponent {
  state = {
    opacity: {
      uv: 1,
      pv: 1,
    },
    ChartData: [],
  };

  componentDidMount() {
    this.fetchData(this.props.userData, this.props.Period);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.Period !== this.props.Period) {
      this.fetchData(this.props.userData, this.props.Period);
    }
  }

  async getDatesBasedOnPeriod(Period) {
    const today = new Date();
    let startDate = new Date();

    switch (Period) {
      case "Today":
        startDate.setDate(today.getDate() - 1);
        break;
      case "Past Week":
        startDate.setDate(today.getDate() - 6);
        break;
      case "Past Month":
        startDate.setDate(today.getDate() - 29);
        break;
      case "Overall":
        startDate.setDate(today.getDate() - 29);
        break;
      default:
        break;
    }

    return [startDate, today];
  }

  async fetchData(UserData, Period) {
    const dailyData = UserData.Daily;
    const result = [];
    const [startDate, endDate] = await this.getDatesBasedOnPeriod(Period);

    let currentDate = new Date(startDate.getTime());

    while (currentDate <= endDate) {
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear();
      const newformattedDate = `${day}/${month}/${year}`;
      const formattedDate = currentDate
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "");

      if (dailyData[formattedDate]) {
        result.push({
          name: newformattedDate,
          "Questions Solve": dailyData[formattedDate].total_question_Attempt,
        });
      } else {
        result.push({
          name: newformattedDate,
          "Questions Solve": 0,
        });
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.setState({ ChartData: result });
  }

  handleMouseEnter = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 0.5 },
    });
  };

  handleMouseLeave = (o) => {
    const { dataKey } = o;
    const { opacity } = this.state;

    this.setState({
      opacity: { ...opacity, [dataKey]: 1 },
    });
  };

  render() {
    const { opacity, ChartData } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={ChartData}
            margin={{
              top: 5,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            />
            <Line
              type="monotone"
              dataKey="Questions Solve"
              strokeOpacity={opacity.pv}
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
